import {
  differenceInCalendarDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
} from 'date-fns';
import {
  DIFFERENCE_BETWEEN_START_AND_END_OF_WEEK,
  MOBILE_DEVICE_ASPECT_RATIO,
} from 'hooks/utils';
import { z } from 'zod';

export const ceilToNearestDecimal = (n: number, decimal = 2) => {
  const places = Math.pow(10, decimal);
  return Math.ceil(n * places) / places;
};

export const ceilToNearest = (n: number, decimal = 2) => n.toFixed(decimal);

export const getISODate = (date?: Date | string | number) =>
  (date ? new Date(date) : new Date()).toISOString();

export const removeEmpty = (obj) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([v]) => v != null)
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
  );
};

const promisifyEventListener = (target, event) => {
  return new Promise((resolve) => {
    const handler = (eventData) => {
      resolve(eventData);
      target.removeEventListener(event, handler);
    };
    target.addEventListener(event, handler);
  });
};

export const getVideoDimensionsFromFile = (file: File) => {
  const video = document.createElement('video');
  video.src = URL.createObjectURL(file);

  return promisifyEventListener(video, 'loadedmetadata');
};

export const areFloatsSimilar = (f1: number, f2: number, places = 4) =>
  Math.round(f1 * Math.pow(10, places)) ===
  Math.round(f2 * Math.pow(10, places));

export const validateVideoSizeRatio = async (file: File) => {
  const { width, height } = await getVideoDimensionsOf(file);
  return (
    width &&
    height &&
    areFloatsSimilar(MOBILE_DEVICE_ASPECT_RATIO, width / height)
  );
};

export const getVideoDimensionsOf = (
  file: File
): Promise<Record<string, number>> =>
  new Promise((resolve) => {
    const video = document.createElement('video');
    video.addEventListener(
      'loadedmetadata',
      function () {
        const height = this.videoHeight;
        const width = this.videoWidth;
        resolve({ height, width });
      },
      false
    );

    video.src = URL.createObjectURL(file);
  });

export const isEmail = (emailString) => {
  try {
    z.string().email().parse(emailString);
    return true;
  } catch {
    return false;
  }
};

export const isOneCalendarWeek = (startDate: Date, endDate: Date) =>
  Math.abs(
    differenceInCalendarDays(
      new Date(endDate).toISOString(),
      new Date(startDate).toISOString()
    )
  ) === DIFFERENCE_BETWEEN_START_AND_END_OF_WEEK;

export const isRemainingPartOfMonth = (startDate: Date, endDate: Date) =>
  isSameMonth(startDate, endDate) && isSameDay(endOfMonth(startDate), endDate);

export const b2Kb = (b?: number) =>
  typeof b !== 'number' || b === 0 ? 0 : Math.ceil(b / 1024);
