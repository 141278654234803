import { Accordion, AccordionDetails, AccordionSummary, Typography, Dialog, DialogContent, IconButton } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Storage } from 'aws-amplify';
import VideoThumbnail from 'components/VideoThumbnail/VideoThumbnail';
import { FC, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import Pagination from '../../../../components/ui/Pagination';
import { usePagination } from '../../../../hooks/usePagination';
import { BrushIcon } from 'assets/icons/icons';
import CloseIcon from '@mui/icons-material/Close';

export interface ICreativeInspirationsProps {
  urls: string[];
  setPauseVideo: (value: boolean) => void;
  expanded: boolean;
  onChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

const CreativeInspirations: FC<ICreativeInspirationsProps> = ({ urls, setPauseVideo, expanded, onChange }) => {
  const [signedUrls, setSignedUrls] = useState<string[]>([]);
  const [selectedInspiration, setSelectedInspiration] = useState<string>('');
  const { page, slicedItems, handlePageChange } = usePagination({
    items: urls,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const getUrls = useCallback(async () => {
    const videoUrls = await Promise.all(
      slicedItems.map((url) => Storage.get(url))
    );
    setSignedUrls(videoUrls);
  }, [slicedItems]);

  const onSelectCreativeInspiration = (url: string) => {
    setPauseVideo(true);
    setSelectedInspiration(url);
    setIsModalOpen(true);
  };

  const videoThumbnails = useMemo(
    () =>
      signedUrls.map((url) => (
        <div key={url} onClick={() => onSelectCreativeInspiration(url)}>
          <VideoThumbnail
            thumbnailWrapperClassNames="w-[170px] h-[190px]"
            key={url}
            videoUrl={url}
          />
        </div>
      )),
    [signedUrls]
  );

  useLayoutEffect(() => {
    getUrls();
  }, [getUrls, page, urls]);

  const handleCloseModal = () => {
    setSelectedInspiration('');
    setIsModalOpen(false);
  };

  return (
    <Accordion
      sx={{
        border: 'none',
        boxShadow: 'none',
        borderRadius: '16px',
        marginBottom: '10px',
        '&.Mui-expanded': {
          border: '1px solid #E0E0E0',
          borderRadius: '16px',
        },
        '&:before': {
          display: 'none',
        },
      }}
      expanded={expanded}
      onChange={onChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          backgroundColor: '#F5F1E8',
          borderRadius: '16px',
          minHeight: '64px',
          '&.Mui-expanded': {
            borderRadius: '16px 16px 0 0',
          },
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '18px',
            fontFamily: 'Oswald, sans-serif',
          },
        }}
      >
        <BrushIcon fontSize="small" />
        <Typography className="uppercase font-oswald font-[600] text-[16px]">
          Creative Inspirations
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '16px',
          fontFamily: 'Inter, sans-serif',
        }}
      >
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-col justify-between items-center py-6 px-4 overflow-y-auto">
            <div className="grid grid-cols-3 gap-y-6 gap-x-4">{videoThumbnails}</div>
            <Pagination
              currentPage={page}
              total={urls.length}
              onPageChange={handlePageChange}
            />
          </div>

          <Dialog
            open={isModalOpen}
            onClose={handleCloseModal}
            PaperProps={{
              style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                overflow: 'hidden',
              },
            }}
            slotProps={{
              backdrop: {
                style: {
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            }}
          >
            <DialogContent
              className="relative flex justify-center items-center p-0"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'transparent',
                overflow: 'hidden',
              }}
            >
              <IconButton
                onClick={handleCloseModal}
                className="fixed top-4 right-4 text-white"
                style={{
                  position: 'fixed',
                  top: '16px',
                  right: '16px',
                  zIndex: 1100,
                  color: '#fff',
                  width: '56px',
                  height: '56px',
                }}
              >
                <CloseIcon
                  style={{
                    fontSize: '36px',
                  }}
                />
              </IconButton>

              <div
                className="relative rounded-3xl"
                style={{
                  position: 'relative',
                  borderRadius: '24px',
                  backgroundColor: 'transparent',
                  overflow: 'hidden',
                  height: window.innerWidth <= 1024 ? '470px' : '820px',
                  width: window.innerWidth <= 1024 ? '270px' : '380px',

                }}
              >
                <video
                  autoPlay
                  controls
                  className="absolute top-0 left-0 rounded-3xl"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: 1,
                    padding: window.innerWidth > 1024
                      ? '20px'
                      : window.innerWidth > 768
                        ? '25px'
                        : '7px',
                  }}
                >
                  <source src={selectedInspiration} type="video/mp4" />
                </video>

                <img
                  src="/images/iPhone-bg.png"
                  className="absolute top-0 left-0 w-full h-full pointer-events-none"
                  style={{
                    zIndex: 2,
                  }}
                  alt="iPhone frame"
                />
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default CreativeInspirations;
