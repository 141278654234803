// src/hoc/withAdmin.tsx
import React from 'react';
import withApolloProvider from 'hooks/apollo/withApollo';
import { useAdminLogic } from 'hooks/useAdminLogic';

interface HocProps {
  shouldCallApi?: boolean;
}

export function withAdmin<T>(
  Component: React.FC<T & HocProps>
): React.FC<T & HocProps> {
  return withApolloProvider((props: T & HocProps) => {
    const adminLogic = useAdminLogic();
    return <Component {...props} {...adminLogic} />;
  });
}