import cn from 'classnames';
import { FC } from 'react';
import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip';
import './CreatorTile.css';

interface ICreatorTileProps {
  title?: string;
  subtitle?: string;
  titleClassNames?: string;
  subtitleClassNames?: string;
  tooltip?: string;
  tooltipId?: string;
  placement?: PlacesType;
  shouldEdit?: boolean;
}

const CreatorTile: FC<ICreatorTileProps> = ({
  title,
  subtitle,
  titleClassNames,
  subtitleClassNames,
  tooltip = '',
  tooltipId = 'default-tooltip',
  placement = 'top',
  shouldEdit = false,
}) => {
  const formattedTooltip =
    shouldEdit && typeof tooltip === 'string' && tooltip.includes('\n') ? (
      <ul className="list-disc ml-4">
        {tooltip.split('\n').map((line, index) => (
          <li key={index}>
            {line.includes(':') ? (
              <>
                <span className="font-bold">{line.split(':')[0]}:</span>{' '}
                {line.split(':')[1]?.trim()}
              </>
            ) : (
              line
            )}
          </li>
        ))}
      </ul>
    ) : (
      tooltip
    );

  return (
    <div className="relative grow flex flex-col rounded-3xl bg-[#F5F1E8] px-[22px] py-[25px] xl:w-[calc(25%-160px)] sm:w-[calc(50%-20px)] w-[calc(100%-20px)] h-[120px]">
      <div className="flex justify-between items-center">
        <span
          className={cn(
            titleClassNames,
            'uppercase text-[14px] font-[700] font-oswald text-gray-500'
          )}
        >
          {title}
        </span>

        {/* Tooltip */}
        {tooltip && (
          <div>
            <img
              className="absolute right-[27px] lg:top-[29px] top-[26px]"
              data-tooltip-id={`tooltip-icon-${tooltipId}`}
              src="/images/info-icon.svg"
              alt="Info"
            />
            <ReactTooltip
              id={`tooltip-icon-${tooltipId}`}
              place={placement}
              className="custom-tooltip-brand-activation"
            >
              {formattedTooltip}
            </ReactTooltip>
          </div>
        )}
      </div>
      <p
        className={cn(
          subtitleClassNames,
          'text-black font-inter font-[600] text-[28px] text-truncate'
        )}
      >
        {subtitle}
      </p>
    </div>
  );
};

export default CreatorTile;
