import withApolloProvider from 'hooks/apollo/withApollo';
import React from 'react';

interface HocProps {
  shouldCallApi?: boolean;
}

export function withPayment<T>(
  Component: React.FC<T & HocProps>
): React.FC<T & HocProps> {
  return withApolloProvider((props: T & HocProps) => {
    // const { shouldCallApi } = props;

    return <Component {...props} />;
  });
}
