import { UserProfile } from 'API';
import GradientCard from 'components/gradientCard/gradientCard';
import PaymentCard from 'components/paymentCard/paymentCard';
import Table, { Tdata } from 'components/table/Table';
import WalletCard from 'components/walletCard/walletCard';
import { CreatePaymentProps, GetUserPaymentDetailProps } from 'hooks/utils';
import { useEffect, useMemo, useState } from 'react';
import { ceilToNearest } from 'utils/utils';
import PaymentDetailsModal from '../../components/paymentCard/PaymentDetailsModal';
import { UseGetCreativeEarnings, UseListCreativeRequestEarnings } from '../../hooks';
import { TABLE_HEADER_CELLS } from './constants';

interface Props {
  data: UserProfile;
  loading?: CreatePaymentProps;
  getPayment?: GetUserPaymentDetailProps;
}

function Wallet({ data }: Props) {
  const [isWalletDetailsDialogOpen, setIsWalletDetailsDialogOpen] =
    useState(false);
  const [lifetimeEarnings, setLifetimeEarnings] = useState(0);
  const [previousEarnings, setPreviousEarnings] = useState(0);
  const [currentEarnings, setCurrentEarnings] = useState(0);
  const [earningsTableData, setEarningsTableData] = useState<
    Record<string, any>[]
  >([]);

  const paymentDetails = useMemo(() => {
    return data.userPaymentDetails;
  }, [data.userPaymentDetails]);

  const { getAllEarnings, allEarningsData } = UseListCreativeRequestEarnings(data.id);

  const {
    getEarnings,
    loading: earningsLoading,
    data: earningsData,
  } = UseGetCreativeEarnings();

  const earnings = useMemo(() => earningsData || [], [earningsData]);
  const allEarnings = useMemo(() => allEarningsData || [], [earningsData]);

  useEffect(() => {
    getEarnings({ variables: { creatorId: data.id } });
    getAllEarnings();
  }, [data.id, getEarnings, getAllEarnings]);

  useEffect(() => {
    if (!earnings || earningsLoading) {
      return;
    }
    console.log(allEarnings);
    const { current, lifetime, previous } = earnings.reduce(
      (a, v) => ({
        current: a.current + (v?.currentEarnings || 0),
        lifetime: a.lifetime + (v?.lifetimeEarnings || 0),
        previous: a.previous + (v?.previousEarnings || 0),
      }),
      {
        current: 0,
        lifetime: 0,
        previous: 0,
      }
    );

    setLifetimeEarnings(lifetime);
    setCurrentEarnings(current);
    setPreviousEarnings(previous);
    setEarningsTableData(
      earnings.map((e) => ({
        creativeUniqueId: e?.creativeUniqueId,
        creatorId: e?.creatorId,
        currentEarnings: (
          <p className="pl-4 text-right">
            ${ceilToNearest(e?.currentEarnings || 0)}
          </p>
        ),
        previousEarnings: (
          <p className="pl-4 text-right">
            ${ceilToNearest(e?.previousEarnings || 0)}
          </p>
        ),
        id: e?.creativeRequestEarningId,
        lifetimeEarnings: (
          <p className="pl-4 text-right">
            ${ceilToNearest(e?.lifetimeEarnings || 0)}
          </p>
        ),
      }))
    );
  }, [earnings, earningsLoading]);

  const onClose = () => {
    setIsWalletDetailsDialogOpen(false);
  };

  return (
    <>
      <div className="sm:grid flex grid-cols-4 lg:gap-[20px] gap-[10px] mb-[20px] w-full lg:overflow-x-visible overflow-x-auto overflow-y-hidden creator-dashboard p-0 lg:pb-0 pb-[4px]">
        <GradientCard>
          <WalletCard
            dueDateCheck={true}
            walletTitle="Current Earnings"
            cost={`$${currentEarnings.toFixed(2)}`}
          />
        </GradientCard>

        <GradientCard>
          <WalletCard
            dueDateCheck={false}
            walletTitle="Previous Earnings"
            cost={`$${previousEarnings.toFixed(2)}`}
          />
        </GradientCard>

        <GradientCard>
          <WalletCard
            dueDateCheck={false}
            walletTitle="Lifetime Earnings"
            cost={`$${lifetimeEarnings.toFixed(2)}`}
          />
        </GradientCard>

        <GradientCard>
          <WalletCard
            dueDateCheck={false}
            walletTitle="Payment Details"
            cost={''}
          ></WalletCard>

          <div className="flex justify-center ">
            <button
              className="creator-button w-[30%]"
              onClick={() => setIsWalletDetailsDialogOpen(true)}
            >
              Update
            </button>
          </div>
        </GradientCard>
      </div>
      <div className="wallet-section">
        <div className="flex gap-x-2 justify-center items-stretch">
          <div>
            <PaymentCard
              transactions={allEarnings}
              profileState={data}
            />
          </div>

          <div className="min-w-[50%] h-auto">
            <Table
              data={earningsTableData as Tdata[]}
              mainlyData={earningsTableData as Tdata[]}
              rows={TABLE_HEADER_CELLS}
              loading={earningsLoading}
              pagination={1}
              extended={false}
              rowWidth="w-[110px]"
              borderColor="#FF872F"
              textPosition="text-right"
            />
          </div>
        </div>

        <PaymentDetailsModal
          userId={data.id}
          onClose={onClose}
          paymentDetails={paymentDetails}
          isOpen={isWalletDetailsDialogOpen}
        />
      </div>
    </>
  );
}

export default Wallet;
