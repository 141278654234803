import { CreateCreativeRequestInput, CREATIVE_STATUS } from 'API';
import classNames from 'classnames';
import { UpdateCreativeRequest } from 'hooks';
import { handleCreativeRequest } from 'hooks/query/useTikTokAuth';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { withAuth } from 'state/auth';
import { withProfile } from 'state/profileSteps';
import { AuthProps, CreatorRoutes, ProfileProps } from 'utils';
import { v4 as uuid } from 'uuid';
import './authorizeTikTok.css';
import AuthorizeTikTokStep1 from './authorizeTikTokStep1';
import AuthorizeTikTokStep2 from './authorizeTikTokStep2';
import AuthorizeTikTokStep3 from './authorizeTikTokStep3';
import AuthorizeTiktokType from './authorizeTiktokType';
import AuthorizeTiktokUpload from './authorizeTikTokUpload';

interface Props {
  onCross: () => void;
  briefId: string;
  BriefName?: string;
  briefDescription?: string;
  brandImageUrl?: string;
  buttonType?: string;
  disableBackground?: boolean;
  brandName?: string;
  isSparkAds?: boolean;
  updatedContentData?: { comment: string; id: string };
  getUpdateData?: (data: any) => void;
  isCreatorBrief?: boolean;
}
export const AuthorizeTikTokHandler: FC<Props & AuthProps & ProfileProps> = ({
  authState,
  disableBackground,
  briefId: id,
  BriefName,
  brandName,
  briefDescription,
  updatedContentData,
  buttonType,
  onCross,
  getUpdateData,
  isSparkAds,
  profileState: { data: profile },
  isCreatorBrief = false
}) => {
  const { createTiktokRequest, loading } = handleCreativeRequest();
  const { updateRequest: updateTiktokRequest, loading: updateLoading } =
    UpdateCreativeRequest();

  const { userId } = authState || {};
  const [step, setStep] = useState<any | null>(isSparkAds === false ? 'upload' : null);
  const [uploading, setUploading] = useState(false);
  const [isUploadFinished, setIsUploadFinished] = useState(false);
  const navigate = useNavigate();

  const submitSteps = async (link: string) => {
    if (!loading && userId && id && profile) {
      const input: CreateCreativeRequestInput = {
        brandBriefId: id,
        BriefName,
        brandName,
        id: uuid(),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        briefDescription,
        creatorId: userId,
        brandComment: [],
        adminComment: [],
        creatorComment: [],
        status: CREATIVE_STATUS.New,
        type: 'CreativeRequest',
        tiktokCreativeUrl: link,
        tiktokVideoCode: link,
        creativeTiktokHandle: profile?.tiktokHandler,
        creativeInstagramHandle: profile?.instagramHandler,
        creativeYoutubeHandle: profile?.youtubeHandler,
        email: profile.email,
      };

      delete input['brief'];

      return createTiktokRequest({
        variables: { input },
        errorPolicy: 'ignore',
      });
    }
  };
  const uploadVideo = async () => {
    if (!loading && userId && id && profile) {
      const input: CreateCreativeRequestInput = {
        brandBriefId: id,
        creatorId: userId,
        BriefName,
        id: uuid(),
        updatedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        brandName,
        briefDescription,
        status: CREATIVE_STATUS.Submitted,
        type: 'CreativeRequest',
        brandComment: [],
        adminComment: [],
        creatorComment: [],
        tiktokCreativeUrl: '',
        tiktokVideoCode: '',
        creativeTiktokHandle: profile?.tiktokHandler,
        creativeInstagramHandle: profile?.instagramHandler,
        creativeYoutubeHandle: profile?.youtubeHandler,
        email: profile.email,
      };

      delete input['brief'];
      return createTiktokRequest({
        variables: { input },
        errorPolicy: 'ignore',
      });
    }
  };

  const updatePath = async (path: string, vidID: string) => {
    if (!updateLoading && userId && id) {
      // TODO: DO we need to check tiktok handler?
      // if (profile?.tiktokHandler) {
      const input = {
        id: vidID,
        tiktokCreativeUrl: path,
      };
      await updateTiktokRequest({ variables: { input }, errorPolicy: 'all' });
      setIsUploadFinished(true);
      navigate(CreatorRoutes.Creatives);
      // }
    }
  };

  const updateCreativeRequest = async (link: string, type: string) => {
    if (updatedContentData?.id && type === 'upload') {
      const input = {
        id: updatedContentData.id,
        tiktokCreativeUrl: link,
        tiktokVideoCode: '',
        type: 'file',
      };
      getUpdateData && getUpdateData(input);
      onCross();
    }
    if (updatedContentData?.id && type === 'tiktokLink') {
      const input = {
        id: updatedContentData.id,
        tiktokCreativeUrl: link,
        tiktokVideoCode: link,
        type: 'link',
      };
      getUpdateData && getUpdateData(input);
      onCross();
    }
  };

  return (
    <div
      className={classNames('tik-tok-wrapper z-[999] ', {
        'tik-tok-background': disableBackground,
      })}
    >
      <div
        className="tik-tok-container"
        onClick={() => !uploading && onCross()}
      >
        {step === null && (
          <AuthorizeTiktokType
            onCross={onCross}
            goToNext={(): void => setStep(0)}
            goToUpload={(): void => setStep('upload')}
            isSparkAds={isSparkAds}
          />
        )}
        {step === 'upload' && (
          <AuthorizeTiktokUpload
            onCross={onCross}
            updatePath={updatePath}
            goToNext={uploadVideo}
            setUploading={setUploading}
            buttonType={buttonType}
            updateCreativeRequest={updateCreativeRequest}
            isUploadFinished={isUploadFinished}
            requestId={updatedContentData?.id}
            goToPrev={(): void => setStep(null)}
            isCreatorBrief={isCreatorBrief}
          />
        )}
        {step === 0 && (
          <AuthorizeTikTokStep1
            onCross={onCross}
            goToNext={(): void => setStep(1)}
          />
        )}
        {step === 1 && (
          <AuthorizeTikTokStep2
            onCross={onCross}
            goToNext={(): void => setStep(2)}
            goToPrev={(): void => setStep(0)}
          />
        )}
        {step === 2 && (
          <AuthorizeTikTokStep3
            onCross={onCross}
            loading={loading}
            goToNext={submitSteps}
            setUploading={setUploading}
            goToPrev={(): void => setStep(1)}
            updateCreativeRequest={updateCreativeRequest}
            requestId={updatedContentData?.id}
            profile={profile}
          />
        )}
      </div>
    </div>
  );
};

export default withAuth(withProfile(AuthorizeTikTokHandler));
