import { BrandBrief } from 'API';
import {
  getBrandBriefList,
  GetCampaignSpent,
  GetCreativeRequests,
  GetCreativeRequestsStats,
} from 'hooks';
import withApolloProvider from 'hooks/apollo/withApollo';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ProfileContext } from 'state/profileSteps';
import { AuthRoutes } from 'utils';
import { BrandBriefProps } from './brandBrief.interface';

export function withBrandBriefs<T>(
  Component: React.FC<T & BrandBriefProps>
): React.FC<T> {
  return withApolloProvider((props: T) => {
    const {
      getBrandBriefslist: getBrandBriefsList,
      loading: brandBriefListLoading,
      data: brandBriefList,
    } = getBrandBriefList();

    const {
      listCreativeRequests,
      data: requests,
      loading: reqLoading,
    } = GetCreativeRequests();
    const { getCampaignSpents, data: spent } = GetCampaignSpent();
    const { getCreativeRequestsStats, data: stats } =
      GetCreativeRequestsStats();
    const {
      profileState: { data },
    } = useContext(ProfileContext);
    const brandId = data?.brand?.items?.[0]?.id;
    const location = useLocation();

    const [brandBriefsState, setBrandBriefsState] =
      useState<Array<BrandBrief | null>>();
    const [totalBrandBriefs, setTotalBrandBriefs] = useState(0);

    useEffect(() => {
      if (!brandId) {
        return;
      }

      const pageSize = location.pathname === AuthRoutes.Dashboard ? 5 : 10;
      getBrandBriefsList({
        variables: {
          brandId,
          page: 0,
          pageSize,
        },
      });
    }, [brandId, getBrandBriefsList, location.pathname]);

    useEffect(() => {
      if (
        !brandBriefListLoading &&
        brandBriefList?.items &&
        !brandBriefsState?.length
      ) {
        setBrandBriefsState(brandBriefList?.items as Array<BrandBrief | null>);
        setTotalBrandBriefs(brandBriefList?.totalItems);
      }
    }, [brandBriefList, brandBriefListLoading]);

    useEffect(() => {
      if (!requests && brandId) {
        listCreativeRequests({
          variables: { brandId, page: 0, pageSize: 10 },
          errorPolicy: 'ignore',
        });
      }
      getCreativeRequestsStats({ variables: { brandId } });
    }, []);

    const changePage = async (type: string, page: number, pageSize: number) => {
      listCreativeRequests({
        variables: { brandId: brandId || '', page, pageSize },
        errorPolicy: 'ignore',
      });
    };

    const changeBriefPage = async (
      type: string,
      page: number,
      pageSize: number
    ) => {
      getBrandBriefsList({
        variables: { brandId, page, pageSize },
      });
    };

    const getCreativeRequests = async ({ page, pageSize, search }) => {
      if (!brandId) {
        return;
      }

      listCreativeRequests({
        variables: { brandId: brandId, page, pageSize, search },
        errorPolicy: 'ignore',
      });
    };

    const getBrandBriefs = async ({ page, pageSize, search }) => {
      if (!brandId) {
        return;
      }

      getBrandBriefsList({
        variables: { brandId, page, pageSize, search },
      });
    };

    useEffect(() => {
      if (brandBriefList?.items?.length && spent === null) {
        const campaigns: string[] = [];
        // eslint-disable-next-line no-unsafe-optional-chaining
        for (const brief of brandBriefList?.items) {
          // @ts-ignore
          if (
            brief?.tikTokData?.campaignId &&
            !campaigns.includes(brief?.tikTokData?.campaignId)
          )
            campaigns.push(brief?.tikTokData?.campaignId);
        }
        getCampaignSpents({
          variables: {
            userId: data?.id,
            campaignId: campaigns[0],
          },
        });
      }
    }, [brandBriefList]);


    const hocProps: BrandBriefProps = {
      data: brandBriefList?.items || [],
      currentPage: brandBriefList?.currentPage,
      requests: requests?.items || [],
      reqPagination: requests?.totalPages,
      reqCurrentPage: requests?.currentPage,
      briefPagination: brandBriefList?.totalPages,
      totalItems: totalBrandBriefs || 0,
      totalRequests: Number(requests?.totalItems) || 0,
      loading: brandBriefListLoading,
      reqLoading: reqLoading,
      brand: data?.brand?.items?.[0],
      contentStats: stats,
      isTiktokLinked: !!data?.tiktokAccountAccess,
      profileState: data,
      spent: spent || '',
      changePage,
      changeBriefPage,
      getBrandBriefs,
      getCreativeRequests,
    };
    return <Component {...props} {...hocProps} />;
  });
}
export default withBrandBriefs;
