import { ErrorMessage } from '@hookform/error-message';
import { AddCircle } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { BrandBrief } from 'API';
import { Storage } from 'aws-amplify';
import classNames from 'classnames';
import { ACCEPT_VIDEO_FILES } from 'components/AdminCreateBrandBrief/SecondPage/constants';
import Select from 'components/Select/Select';
import Switch from 'components/Switch/Switch';
import { StyledTextField } from 'components/TextField';
import { ENTER_KEY, ENTER_KEY_CODE } from 'hooks/utils';
import FileUpload from 'pages/adminCreativeApproval/components/components/FileUpload';
import {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { validateVideoSizeRatio } from 'utils/utils';
import { BlueCloseIcon } from '../../../../../assets/icons/icons';
import { getUrlFilename } from '../../../../../utils/aws.utils';
import { FILE_INPUT_EMPTY_MESSAGE } from '../../constants';
import { IPractices } from '../../FirstPage';
import { COUNTRY_OPTIONS } from '../CountryOptions/constants';
import Tags from '../Tags/Tags';
import { compressVideo } from "../../../../../utils/videoUtils";

export interface IMainTabProps {
  brandBrief?: BrandBrief | null;
  toggleNextButton: () => void;
  practicesState: IPractices;
  onPracticeAppend: (sourceField: string, value: string) => void;
  onPracticeRemove: (sourceField: string, filteredValue: string[]) => void;
}

type TMultiValueInputCallbackArguments = {
  sourceField: string;
  destinationField: string;
  value?: string;
};

const AddIconButton = ({
  className,
  onClick,
  ...props
}: {
  className?: string;
  onClick: () => void;
}) => {
  return (
    <IconButton aria-label="fingerprint" size="large" color="default">
      <AddCircle
        fontSize="large"
        className={classNames('w-[30px] h-[30px]', className)}
        {...props}
        onClick={onClick}
      />
    </IconButton>
  );
};

const ListItem = ({
  content,
  onRemove,
}: {
  content: string;
  onRemove: MouseEventHandler<SVGElement>;
}) => (
  <div
    key={content}
    className="flex justify-between items-center space-x-4 max-w-[350px]"
  >
    <p className="truncate">
      {content.startsWith('• ') ? content : `• ${content}`}
    </p>
    <IconButton aria-label="fingerprint" size="small" color="default">
      <BlueCloseIcon onClick={onRemove} />
    </IconButton>
  </div>
);

const MainTab: FC<IMainTabProps> = ({
  brandBrief,
  toggleNextButton,
  onPracticeAppend,
  practicesState,
  onPracticeRemove,
}) => {
  const [briefTags, setBriefTags] = useState<string[]>(brandBrief?.tags || []);
  const [overviewVideo, setOverviewVideo] = useState<File | null>(null);
  const overviewUrlFilename = getUrlFilename(
    brandBrief?.overviewVideoUrl || ''
  );
  const [selectedVideoMsg, setSelectedVideoMsg] = useState(
    overviewUrlFilename || FILE_INPUT_EMPTY_MESSAGE
  );
  const {
    register,
    trigger,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (!brandBrief) {
      return;
    }

    setValue(
      'country',
      COUNTRY_OPTIONS.find(({ value }) => value === brandBrief.country)?.value
    );
    setValue('active', brandBrief.active);
  }, [brandBrief, setValue]);

  const onSelectOverviewVideo = async (files: File[]) => {
    const file = files?.[0];
    if (!file) {
      return;
    }
    setSelectedVideoMsg(file.name);
    setOverviewVideo(file);
  };

  const uploadOverviewVideoToS3 = useCallback(
    async ({ fileName, file, level, contentType }) => {
      const isValidAspectRatio = await validateVideoSizeRatio(file);
      if (!isValidAspectRatio) {
        toast.error('Invalid aspect ratio. Should have 16/9');
        return;
      }

      try {
        // 2. Disable or toggle UI to indicate in-progress
        toggleNextButton();
        toast.success('Uploading file...');

        // 3. Compress the file before uploading
        console.log("Compressing file");
        const compressedFile = await compressVideo(file);
        console.log("File compressed");
        console.log("storing");


        // 4. Upload the compressed file to S3
        await Storage.put(fileName, compressedFile, { level, contentType });
        console.log("stored");

        // 5. Update your form or component state
        setValue('overviewVideoUrl', fileName);
        trigger('overviewVideoUrl');

        toast.success('Uploaded');
      } catch (error) {
        console.error('Upload failed:', error);
        toast.error('Upload failed');
      } finally {
        // 6. Re-enable or toggle UI
        toggleNextButton();
      }
    },
    [setValue, toggleNextButton]
  );

  useEffect(() => {
    if (overviewVideo == null || !brandBrief?.id) {
      return;
    }

    uploadOverviewVideoToS3({
      fileName: `brief/${brandBrief?.id}/${overviewVideo?.name}`,
      file: overviewVideo,
      level: 'public',
      contentType: overviewVideo?.type,
    });
  }, [overviewVideo]);

  const onAppendMultiline = useCallback(
    ({ sourceField, destinationField }: TMultiValueInputCallbackArguments) => {
      const newValue = getValues(sourceField);

      onPracticeAppend(sourceField, newValue);
      setValue(destinationField, [
        ...new Set([...practicesState[sourceField], newValue]),
      ]);
      setValue(sourceField, '');
      trigger(destinationField);
    },
    [practicesState, onPracticeAppend]
  );

  const onRemoveMultiline = useCallback(
    ({
      sourceField,
      destinationField,
      value,
    }: TMultiValueInputCallbackArguments) => {
      const filteredValue = practicesState[sourceField].filter((val) => {
        return val !== value;
      });
      onPracticeRemove(sourceField, filteredValue);
      setValue(destinationField, filteredValue);
      trigger(destinationField);
    },
    [practicesState, onPracticeRemove]
  );

  const onAppendTags = (event) => {
    const tag = event.target.value?.trim();
    if (event.keyCode !== ENTER_KEY_CODE || !tag || !brandBrief) {
      return;
    }

    const tags = getValues('tags') || briefTags;
    const updatedTags = [...new Set([...(tags || []), tag])];

    setValue('tags', updatedTags);
    setBriefTags(updatedTags);

    event.target.value = '';
    event.preventDefault();
  };

  const onDeleteTag = (tag?: string) => {
    if (!brandBrief || !tag) {
      return;
    }

    const tags = getValues('tags') || briefTags;
    const updatedTags = tags.filter((t) => t !== tag);

    setValue('tags', updatedTags);
    setBriefTags(updatedTags);
  };

  const goodPractices = useMemo(
    () =>
      practicesState['goodPractices']
        ?.filter((p) => p)
        .map((p, index) => {
          return (
            <ListItem
              key={index}
              content={p}
              onRemove={() =>
                onRemoveMultiline({
                  sourceField: 'goodPractices',
                  destinationField: 'goodPracticesSecondary',
                  value: p,
                })
              }
            />
          );
        }),
    [practicesState?.goodPractices]
  );
  const badPractices = useMemo(() => {
    return practicesState['badPractices']
      ?.filter((p) => p)
      .map((p, index) => {
        return (
          <ListItem
            key={index}
            content={p}
            onRemove={() =>
              onRemoveMultiline({
                sourceField: 'badPractices',
                destinationField: 'badPracticesSecondary',
                value: p,
              })
            }
          />
        );
      });
  }, [practicesState?.badPractices]);
  const maybes = useMemo(
    () =>
      practicesState['maybes']
        ?.filter((p) => p)
        .map((p, index) => {
          return (
            <ListItem
              key={index}
              content={p}
              onRemove={() =>
                onRemoveMultiline({
                  sourceField: 'maybes',
                  destinationField: 'maybesSecondary',
                  value: p,
                })
              }
            />
          );
        }),
    [practicesState?.maybes]
  );
  const commonRejectionReasons = useMemo(
    () =>
      practicesState['commonRejectionReasons']
        ?.filter((p) => p)
        .map((p, index) => {
          return (
            <ListItem
              key={index}
              content={p}
              onRemove={() =>
                onRemoveMultiline({
                  sourceField: 'commonRejectionReasons',
                  destinationField: 'commonRejectionSecondary',
                  value: p,
                })
              }
            />
          );
        }),
    [practicesState?.commonRejectionReasons]
  );

  return (
    <>
      <h2 className="text-[#0E0D0D] uppercase head-text text-[16px] font-[700] m-0 mb-3">
        Brand name activation setup - 1 of 3
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex flex-col gap-4">
          <StyledTextField
            label="Activation Name"
            fullWidth={true}
            error={!!errors.activationName}
            required={true}
            {...register('BriefName')}
          />
          <ErrorMessage
            errors={errors}
            name="BriefName"
            as={'p'}
            className="text-red-500 text-[12px]"
          />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 items-center">
            <Select
              options={COUNTRY_OPTIONS}
              label="Country"
              {...register('country')}
            />

            <Switch label="Active / Inactive" {...register('active')} />
          </div>

          <StyledTextField
            label="Activation overview"
            fullWidth={true}
            multiline
            rows={3}
            required={true}
            {...register('overview')}
          />
          <ErrorMessage
            errors={errors}
            name="overview"
            as={'p'}
            className="text-red-500 text-[12px]"
          />

          <FileUpload
            onFileSelect={onSelectOverviewVideo}
            accept={ACCEPT_VIDEO_FILES}
            emptyMessage={selectedVideoMsg}
            label="Click to upload brand activation video"
          />
          <ErrorMessage
            errors={errors}
            name="overviewVideoUrl"
            as={'p'}
            className="text-red-500 text-[12px]"
          />

          <StyledTextField
            label="Activation Objective"
            fullWidth={true}
            required={true}
            {...register('objective')}
          />
          <ErrorMessage
            errors={errors}
            name="objective"
            as={'p'}
            className="text-red-500 text-[12px]"
          />

          <StyledTextField
            label="Target Audience"
            fullWidth={true}
            required={true}
            {...register('TargetAudience')}
          />
          <ErrorMessage
            errors={errors}
            name="TargetAudience"
            as={'p'}
            className="text-red-500 text-[12px]"
          />

          <StyledTextField
            label="Activation Messaging"
            fullWidth={true}
            multiline
            rows={3}
            {...register('messaging')}
            required={true}
          />
          <ErrorMessage
            errors={errors}
            name="messaging"
            as={'p'}
            className="text-red-500 text-[12px]"
          />

          <StyledTextField
            fullWidth={true}
            label="Brand / Activation tone"
            {...register('tone')}
            required={true}
          />
          <ErrorMessage
            errors={errors}
            name="tone"
            as={'p'}
            className="text-red-500 text-[12px]"
          />
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-y-2">
            <div className="flex gap-1">
              <StyledTextField
                label="Must include"
                fullWidth
                InputLabelProps={{ shrink: true }}
                {...register('goodPractices')}
                onKeyDown={(event) => {
                  if (event.key !== ENTER_KEY) {
                    return;
                  }

                  onAppendMultiline({
                    sourceField: 'goodPractices',
                    destinationField: 'goodPracticesSecondary',
                  });
                }}
              />
              <AddIconButton
                onClick={() =>
                  onAppendMultiline({
                    sourceField: 'goodPractices',
                    destinationField: 'goodPracticesSecondary',
                  })
                }
              />
            </div>

            <div className="flex flex-col max-h-[100px] overflow-y-auto">
              {goodPractices}
            </div>

            <ErrorMessage
              errors={errors}
              name="goodPracticesSecondary"
              as={'p'}
              className="text-red-500 text-[12px]"
            />
          </div>

          <div className="flex flex-col gap-y-2">
            <div className="flex gap-1">
              <StyledTextField
                label="Must not include"
                fullWidth
                InputLabelProps={{ shrink: true }}
                {...register('badPractices')}
                onKeyDown={(event) => {
                  if (event.key !== ENTER_KEY) {
                    return;
                  }

                  onAppendMultiline({
                    sourceField: 'badPractices',
                    destinationField: 'badPracticesSecondary',
                  });
                }}
              />
              <AddIconButton
                onClick={() =>
                  onAppendMultiline({
                    sourceField: 'badPractices',
                    destinationField: 'badPracticesSecondary',
                  })
                }
              />
            </div>

            <div className="flex flex-col max-h-[100px] overflow-y-auto">
              {badPractices}
            </div>

            <ErrorMessage
              errors={errors}
              name="badPracticesSecondary"
              as={'p'}
              className="text-red-500 text-[12px]"
            />
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex space-x-1">
              <StyledTextField
                fullWidth
                label="Maybes"
                InputLabelProps={{ shrink: true }}
                onKeyDown={(event) => {
                  if (event.key !== ENTER_KEY) {
                    return;
                  }

                  onAppendMultiline({
                    sourceField: 'maybes',
                    destinationField: 'maybesSecondary',
                  });
                }}
                {...register('maybes')}
              />
              <AddIconButton
                onClick={() => {
                  onAppendMultiline({
                    sourceField: 'maybes',
                    destinationField: 'maybesSecondary',
                  });
                }}
              />
            </div>

            <div className="flex flex-col max-h-[100px] overflow-y-auto">
              {maybes}
            </div>

            <ErrorMessage
              errors={errors}
              name="maybesSecondary"
              as={'p'}
              className="text-red-500 text-[12px]"
            />

            <div className="flex space-x-1">
              <StyledTextField
                label="Common Rejection reasons"
                fullWidth
                InputLabelProps={{ shrink: true }}
                {...register('commonRejectionReasons')}
                onKeyDown={(event) => {
                  if (event.key !== ENTER_KEY) {
                    return;
                  }

                  onAppendMultiline({
                    sourceField: 'commonRejectionReasons',
                    destinationField: 'commonRejectionSecondary',
                  });
                }}
              />
              <AddIconButton
                onClick={() => {
                  onAppendMultiline({
                    sourceField: 'commonRejectionReasons',
                    destinationField: 'commonRejectionSecondary',
                  });
                }}
              />
            </div>

            <div className="flex flex-col max-h-[100px] overflow-y-auto">
              {commonRejectionReasons}
            </div>

            <ErrorMessage
              errors={errors}
              name="commonRejectionSecondary"
              as={'p'}
              className="text-red-500 text-[12px]"
            />

            <Tags
              tags={briefTags}
              onKeyDown={onAppendTags}
              onDeleteTag={onDeleteTag}
              {...register('tags')}
            />
            <ErrorMessage
              errors={errors}
              name="tags"
              as={'p'}
              className="text-red-500 text-[12px]"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainTab;
