import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import './Header.css';
import { HiMenu, HiX } from 'react-icons/hi';
import { UnAuthRoutes } from 'utils';

const BASE_URL = process.env.REACT_APP_URL || '';

// Header Component
const Header: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(UnAuthRoutes.TermsAndConditions); // Navigates to the TermsAndConditions route
  };

  // Define the drop-in animation using react-spring
  const dropIn = useSpring({
    from: { transform: 'translate(-50%, -100%)', opacity: 0 },
    to: { transform: 'translate(-50%, 0%)', opacity: 1 },
    config: { tension: 150, friction: 30, duration: 1000 },
  });

  // Handle Login navigation to an external link
  const handleLoginClick = (): void => {
    window.location.href = 'https://app.edcsquared.io/login';
  };

  // Toggle Mobile Menu
  const toggleMobileMenu = (): void => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  return (
    <>
      {/* Desktop Navbar */}
      <animated.nav style={dropIn} className="navbar">
        <div className="logo">
          <Link to={`${BASE_URL}/`}>
            <img src={"/images/edc-logo.png"} alt="EDC SQUARED Logo" className="logo-image" />
          </Link>
        </div>
        <div className="nav-container">
          <ul className="nav-links">
            <li><Link to={`${BASE_URL}/for-creators`} className="nav-button">For Creators</Link></li>
            <li><Link to={`${BASE_URL}/for-brands`} className="nav-button">For Brands</Link></li>
            <li><Link to={`${BASE_URL}/our-purpose`} className="nav-button">Our Purpose</Link></li>
            <li><Link to={`${BASE_URL}/our-news`} className="nav-button">Our News / Story</Link></li>
            <li><Link to={`${BASE_URL}/lets-talk`} className="nav-button">Let's Connect</Link></li>
          </ul>

          <button onClick={handleNavigate} className="navigate-button">
            View Terms and Conditions
          </button>
          <button className="login-button" onClick={handleLoginClick}>
            Login / Sign Up
          </button>
        </div>
      </animated.nav>

      {/* Mobile Navbar Background */}
      <div className="mobile-navbar-background" />

      {/* Mobile Navbar */}
      <div className="mobile-navbar">
        <div className="mobile-navbar-header">
          <Link to={`${BASE_URL}/`}>
            <img src={"/images/edc-logo.png"} alt="EDC SQUARED Logo" className="logo-image" />
          </Link>
          <button className="mobile-menu-icon" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <HiX size={30} /> : <HiMenu size={30} />}
          </button>
        </div>
        {isMobileMenuOpen && (
          <div className="mobile-nav-links">
            <ul>
              <li><Link to={`${BASE_URL}/for-creators`} className="nav-button" onClick={toggleMobileMenu}>For Creators</Link></li>
              <li><Link to={`${BASE_URL}/for-brands`} className="nav-button" onClick={toggleMobileMenu}>For Brands</Link></li>
              <li><Link to={`${BASE_URL}/our-purpose`} className="nav-button" onClick={toggleMobileMenu}>Our Purpose</Link></li>
              <li><Link to={`${BASE_URL}/our-news`} className="nav-button" onClick={toggleMobileMenu}>Our News / Story</Link></li>
              <li><Link to={`${BASE_URL}/lets-talk`} className="nav-button" onClick={toggleMobileMenu}>Let's Connect</Link></li>
            </ul>
            <button className="login-button" onClick={handleLoginClick}>
              Login / Sign Up
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;